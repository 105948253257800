<template>
  <v-container class="full-height-container px-6">
    <v-row>
      <v-col cols="12" class="d-flex align-center flex-column login-container">
        <v-img
          v-if="isLogoBase64 || !customLogo"
          :width="$vuetify.breakpoint.mobile ? 171 : 200"
          :height="$vuetify.breakpoint.mobile ? 60 : 60"
          contain
          :src="customLogo ? customLogo : require('@/assets/logo.svg')"
          class="mb-10"
        ></v-img>
        <div
          v-else
          v-html="customLogo"
          class="mb-10 d-flex"
          :width="$vuetify.breakpoint.mobile ? 171 : 200"
          :height="$vuetify.breakpoint.mobile ? 60 : 60"
        ></div>
        <v-card elevation="4" class="d-flex" v-if="emailVerification">
          <v-card-title>
            <h5
              class="font-weight-800 text-center w-100"
              style="font-size: 22px !important"
            >
              {{ $t("heading.loginPage.emailVerification.title") }}
            </h5>
          </v-card-title>
          <v-card-text>
            <template v-if="verificationErrors.length">
              <div
                class="login-error-message mb-6"
                v-for="(error, index) in verificationErrors"
                :key="index"
              >
                <div class="login-error-message__icon-box">
                  <v-icon size="24" color="error">$alertwarning</v-icon>
                </div>
                <div class="login-error-message__text-box">
                  <h6 class="p-3 login-error-message__title pb-0">
                    {{ error }}
                  </h6>
                </div>
              </div>
            </template>
            <v-form class="form" @submit.prevent>
              <v-text-field
                outlined
                :label="$t('form.placeholder.code')"
                v-model="verificationCode"
                large
                solo
                flat
                hide-details="auto"
                validate-on-blur
                @keydown.enter="verifyCode()"
              >
                <template v-slot:prepend-inner>
                  <v-icon size="24" class="text--body"> $keyIcon</v-icon>
                </template>
              </v-text-field>

              <span class="p-4 mb-5">
                {{ $t("heading.loginPage.emailVerification.info1") }}
                {{ email }}
                {{ $t("heading.loginPage.emailVerification.info2") }}
              </span>
            </v-form>
            <v-btn
              large
              @click.prevent="verifyCode()"
              color="primary"
              block
              elevation="0"
              :loading="verifying"
            >
              <span class="font-weight-600">{{ $t("button.verify") }}</span>
              <template v-slot:loader>
                <loader :color="'white'" :size="24" />
              </template>
            </v-btn>

            <v-btn
              class="mt-4"
              @click="resendCode()"
              block
              x-large
              outlined
              elevation="0"
              :loading="verifying"
              color="gray"
            >
              <span class="heading--text font-weight-bold">{{
                $t("button.resendCode")
              }}</span>
            </v-btn>

            <v-btn
              class="mt-4"
              @click="emailVerification = false"
              block
              x-large
              outlined
              elevation="0"
              color="gray"
            >
              <span class="heading--text font-weight-bold">{{
                $t("button.back")
              }}</span>
            </v-btn>
            <span class="p-4 mb-5">
              {{ $t("heading.loginPage.emailVerification.info3") }}
            </span>
          </v-card-text>
        </v-card>
        <v-card v-else elevation="4" class="d-flex">
          <v-card-title class="d-flex justify-center">
            <h1 class="font-weight-800">
              {{ $t("Register") }}
            </h1>
          </v-card-title>
          <v-card-text>
            <div class="d-flex justify-space-between align-center">
              <div class="d-flex align-center">
                <div>
                  {{ $t("Already registered?") }}
                  <a @click.prevent="$router.push({ path: '/login' })">{{
                    $t("button.logIn")
                  }}</a>
                </div>
              </div>
            </div>
            <template v-if="errors.length">
              <collapse-transitions enabled>
                <div
                  class="login-error-message mb-6"
                  v-for="(error, index) in errors"
                  :key="index"
                >
                  <div class="login-error-message__icon-box">
                    <v-icon size="24" color="error">$alertwarning</v-icon>
                  </div>
                  <div class="login-error-message__text-box">
                    <h6 class="p-3 login-error-message__title">
                      {{ error }}
                    </h6>
                    <p
                      v-if="$store.state.home.app.support_link"
                      class="mb-0 p-3 login-error-message__desc"
                    >
                      {{ $t("message.support.before") }}
                      <a
                        :href="$store.state.home.app.support_link"
                        class="info--text"
                        >{{ $t("message.support.link") }}</a
                      >
                      {{ $t("message.support.after") }}
                    </p>
                  </div>
                </div>
              </collapse-transitions>
            </template>
            <v-form class="form" ref="form">
              <v-text-field
                outlined
                :label="$t('form.label.emailAddress')"
                v-model="email"
                :rules="emailRules"
                large
                solo
                flat
                hide-details="auto"
                validate-on-blur
              >
                <template v-slot:prepend-inner>
                  <v-icon color="headings--text">$email</v-icon>
                </template>
              </v-text-field>
              <v-text-field
                outlined
                :label="$t('Confirm Email')"
                v-model="emailConfirm"
                :rules="emailRules"
                large
                solo
                flat
                hide-details="auto"
                validate-on-blur
              >
                <template v-slot:prepend-inner>
                  <v-icon color="headings--text">$email</v-icon>
                </template>
              </v-text-field>
              <v-text-field
                outlined
                :label="$t('form.label.password')"
                type="password"
                v-model="password"
                :rules="registerPasswordRules"
                solo
                flat
                hide-details="auto"
                validate-on-blur
              >
                <template v-slot:prepend-inner>
                  <v-icon color="headings--text">$lockoutline</v-icon>
                </template>
              </v-text-field>
              <v-text-field
                outlined
                :label="$t('Confirm Password')"
                type="password"
                v-model="passwordConfirm"
                :rules="registerPasswordRules"
                solo
                flat
                hide-details="auto"
                validate-on-blur
              >
                <template v-slot:prepend-inner>
                  <v-icon color="headings--text">$lockoutline</v-icon>
                </template>
              </v-text-field>
            </v-form>

            <div class="d-flex justify-space-between align-center">
              <div class="d-flex align-center">
                <div>
                  {{ $t("By registering, you accept") }}
                  <a>{{ $t("Terms of service") }}</a>
                </div>
              </div>
            </div>

            <v-btn
              large
              @click.prevent="register()"
              color="primary"
              block
              elevation="0"
              class="mt-6"
              :loading="registering"
            >
              <span class="font-weight-600">{{ $t("button.register") }}</span>

              <template v-slot:loader>
                <loader :color="'white'" :size="24" />
              </template>
            </v-btn>
            <v-btn
              v-for="provider in authProviders"
              :key="provider"
              @click.prevent="socialAuth(provider)"
              :loading="authLoading"
            >
              {{ $t("Sign in with") }} {{ provider }}
            </v-btn>
          </v-card-text>
        </v-card>
        <LanguageSwitcher isLogin class="mt-10" />
      </v-col>
    </v-row>
    <v-img
      :src="require('@/assets/illustrations/radio-lines.svg')"
      class="radio-lines"
    />
  </v-container>
</template>

<script>
import Api from "@/apis/Api";
import Auth from "@/apis/Auth";

import FormRulesMixin from "../mixins/FormRulesMixin";
import Loader from "../components/Loader.vue";

import CollapseTransitions from "../components/transitions/CollapseTransitions.vue";
import LanguageSwitcher from "../components/LanguageSwitcher.vue";

export default {
  components: {
    CollapseTransitions,
    Loader,
    LanguageSwitcher,
  },
  mixins: [FormRulesMixin],
  data: function () {
    return {
      email: "",
      emailConfirm: "",
      password: "",
      passwordConfirm: "",
      errors: [],
      registering: false,
      authLoading: false,
      emailVerification: false,
      verificationErrors: [],
      verificationCode: "",
      verifying: false,
    };
  },
  beforeRouteEnter: async (__, _, next) => {
    await Auth.remoteAuthCheck().then((response) => {
      if (response === true) {
        next((vm) => {
          vm.$root.$emit("loggedIn", true);
          next(vm.$route.query.redirect || "/");
        });
      } else {
        next();
      }
    });
  },
  computed: {
    customLogo: function () {
      if (!this.$store.state.ThemeModule.logo) {
        return false;
      }

      return this.$store.state.ThemeModule.logo;
    },
    isLogoBase64: function () {
      return (
        this.customLogo && this.customLogo.trim().startsWith("data:image/")
      );
    },
    authProviders() {
      return this.$store.state.home.app.integrations.auth;
    },
  },
  mounted: function () {
    document.addEventListener("keyup", (e) => this.handleEnter(e));
    Auth.loadRecaptchaScript();
  },
  destroyed: function () {
    document.removeEventListener("keyup", (e) => this.handleEnter(e));
  },
  methods: {
    handleEnter(e) {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        this.register();
      }
    },
    //
    register() {
      if (!this.$refs.form.validate()) return;
      this.registering = true;
      Auth.register(this.email, this.password)
        .then((response) => {
          if (response.data.verify_email == true) {
            this.emailVerification = true;
            return;
          }
          this.$root.$emit("loggedIn", true);
          Auth.hideRecaptchaBadge();
          this.$router.push({ path: this.$route.query.redirect || "/" });
        })
        .catch((error) => {
          this.errors = Api.getErrorMessages(error);
        })
        .finally(() => {
          this.registering = false;
        });
    },
    verifyCode() {
      this.verifying = true;
      Auth.verifyCode(this.verificationCode)
        .then(() => {
          this.$root.$emit("loggedIn", true);
          Auth.hideRecaptchaBadge();
          this.$router.push({ path: this.$route.query.redirect || "/" });
        })
        .catch((error) => {
          this.verificationErrors = Api.getErrorMessages(error);
        })
        .finally(() => {
          this.verifying = false;
        });
    },
    resendCode() {
      this.verifying = true;
      Api.post("/resend-code")
        .catch((error) => {
          this.verificationErrors = Api.getErrorMessages(error);
        })
        .finally(() => {
          this.verifying = false;
        });
    },
    socialAuth(provider) {
      this.authLoading = true;
      Api.get(`/auth/${provider}/redirect-url`)
        .then((response) => {
          window.location = response.data.data.url;
        })
        .catch((error) => {
          this.errors = [Api.getErrorMessage(error)];
        })
        .finally(() => {
          this.authLoading = false;
        });
    },
  },
};
</script>

<style src="./Login.scss" lang="scss" scoped />
