<template><div></div></template>

<script>
import Auth from "../apis/Auth";
export default {
  created() {
    Auth.socialLogin(this.$route.params.provider)
      .then(() => {
        this.$router.push({ path: "/instances" });
      })
      .catch(() => {
        this.$router.push({ path: "/login" });
      });
  },
};
</script>
