<template>
  <v-container class="full-height-container px-6">
    <v-row>
      <v-col cols="12" class="d-flex align-center flex-column login-container">
        <v-img
          v-if="isLogoBase64 || !customLogo"
          :width="$vuetify.breakpoint.mobile ? 171 : 200"
          :height="$vuetify.breakpoint.mobile ? 60 : 60"
          contain
          :src="customLogo ? customLogo : require('@/assets/logo.svg')"
          class="mb-10"
        ></v-img>
        <div
          v-else
          v-html="customLogo"
          class="mb-10 d-flex"
          :width="$vuetify.breakpoint.mobile ? 171 : 200"
          :height="$vuetify.breakpoint.mobile ? 60 : 60"
        ></div>
        <v-card elevation="4" class="d-flex" v-if="twoFactorAuthEnabled">
          <v-card-title>
            <h5
              class="font-weight-800 text-center w-100"
              style="font-size: 22px !important"
            >
              {{ $t("heading.loginPage.twoFactorAuthentication.title") }}
            </h5>
          </v-card-title>
          <v-card-text>
            <template v-if="twoFAErrors.length">
              <!-- <collapse-transitions
                :enabled="twoFactorAuthEnabled"> -->
              <div
                class="login-error-message mb-6"
                v-for="(error, index) in twoFAErrors"
                :key="index"
              >
                <div class="login-error-message__icon-box">
                  <v-icon size="24" color="error">$alertwarning</v-icon>
                </div>
                <div class="login-error-message__text-box">
                  <h6 class="p-3 login-error-message__title pb-0">
                    {{ error }}
                  </h6>
                </div>
              </div>
              <!-- </collapse-transitions> -->
            </template>
            <v-form class="form" @submit.prevent>
              <v-text-field
                outlined
                :label="$t('form.placeholder.code')"
                v-model="twoFactorAuthCode"
                large
                solo
                flat
                hide-details="auto"
                validate-on-blur
                @keydown.enter="loginAfterTwoFactorAuth()"
              >
                <template v-slot:prepend-inner>
                  <v-icon size="24" class="text--body"> $keyIcon</v-icon>
                </template>
              </v-text-field>

              <span class="p-4 mb-5">{{
                $t("heading.loginPage.twoFactorAuthentication.info")
              }}</span>
            </v-form>
            <v-btn
              large
              @click.prevent="loginAfterTwoFactorAuth()"
              color="primary"
              block
              elevation="0"
              :loading="loading"
            >
              <span class="font-weight-600">{{ $t("button.verify") }}</span>
              <template v-slot:loader>
                <loader :color="'white'" :size="24" />
              </template>
            </v-btn>

            <v-btn
              class="mt-4"
              @click="twoFactorAuthBack()"
              block
              x-large
              outlined
              elevation="0"
              color="gray"
            >
              <span class="heading--text font-weight-bold">{{
                $t("button.back")
              }}</span>
            </v-btn>
          </v-card-text>
        </v-card>

        <v-card elevation="4" class="d-flex" v-else-if="backupCodeRegenerated">
          <v-card-title>
            <h5
              class="font-weight-800 text-center w-100"
              style="font-size: 22px !important"
            >
              {{ $t("heading.loginPage.recoveryCode.title") }}
            </h5>
          </v-card-title>

          <v-card-text>
            <div class="recovery-code-box">
              {{ recoveryCode[0] }}

              <copy-text-button
                :text="recoveryCode[0]"
                :color="'primary'"
                :size="24"
              />
            </div>

            <div
              class="alert alert--error alert--outlined mt-4"
              style="padding: 10px; min-width: unset !important"
            >
              <div class="alert__icon-box">
                <v-icon color="error" size="20">$alertwarning</v-icon>
              </div>
              <div class="alert__text-box alert__text-box--wide">
                <!-- <p class="p-2 font-weight-bold mb-3">Error Message</p> -->

                <p class="mb-0 p-3 text-gray-darken-1">
                  <template>
                    {{ $t("heading.loginPage.recoveryCode.alert") }}
                  </template>
                </p>
              </div>
            </div>

            <div class="mt-2">
              <h4
                class="font-weight-600"
                style="
                  font-weight: $font-weight-semibold;
                  font-size: 16px;
                  line-height: 24px;
                "
              >
                {{ $t("message.confirmAction") }}
              </h4>
              <label class="base--text checkbox-label d-flex align-center p-2">
                <checkbox
                  class="mt-4 align-start"
                  outlined
                  :label="
                    $t('heading.loginPage.recoveryCode.confirmationCheckbox')
                  "
                  color="primary"
                  dense
                  @change="confirmCheckbox"
                ></checkbox>
              </label>
            </div>
            <v-btn
              x-large
              @click.prevent="continueAfterBackupCode"
              color="primary"
              block
              elevation="0"
              :loading="loading"
              :disabled="!confirmedRecoverCode"
              class="mt-4"
            >
              <span class="font-weight-600">{{ $t("button.continue") }}</span>
              <template v-slot:loader>
                <loader :color="'white'" :size="24" />
              </template>
            </v-btn>
          </v-card-text>
        </v-card>

        <v-card elevation="4" class="d-flex" v-else>
          <v-card-title class="d-flex justify-center">
            <h1 class="font-weight-800 text-center">
              {{ $t("heading.loginPage.login.title") }}
            </h1>
          </v-card-title>
          <v-card-text>
            <template v-if="errors.length">
              <collapse-transitions
                :enabled="!twoFactorAuthEnabled && !backupCodeRegenerated"
              >
                <div
                  class="login-error-message mb-6"
                  v-for="(error, index) in errors"
                  :key="index"
                >
                  <div class="login-error-message__icon-box">
                    <v-icon size="24" color="error">$alertwarning</v-icon>
                  </div>
                  <div class="login-error-message__text-box">
                    <h6 class="p-3 login-error-message__title">
                      {{ error }}
                    </h6>
                    <p
                      v-if="$store.state.home.app.support_link"
                      class="mb-0 p-3 login-error-message__desc"
                    >
                      {{ $t("message.support.before") }}
                      <a
                        :href="$store.state.home.app.support_link"
                        class="info--text"
                        >{{ $t("message.support.link") }}</a
                      >
                      {{ $t("message.support.after") }}
                    </p>
                  </div>
                </div>
              </collapse-transitions>
            </template>
            <v-form class="form" ref="form">
              <v-text-field
                outlined
                :label="$t('form.label.emailAddress')"
                v-model="email"
                :rules="emailRules"
                large
                solo
                flat
                hide-details="auto"
                validate-on-blur
              >
                <template v-slot:prepend-inner>
                  <v-icon color="headings--text">$email</v-icon>
                </template>
              </v-text-field>
              <v-text-field
                outlined
                :label="$t('form.label.password')"
                type="password"
                v-model="password"
                :rules="adminPasswordRules"
                solo
                flat
                hide-details="auto"
                validate-on-blur
              >
                <template v-slot:prepend-inner>
                  <v-icon color="headings--text">$lockoutline</v-icon>
                </template>
              </v-text-field>
            </v-form>

            <div
              class="d-flex justify-space-between align-center"
              style="gap: 8px"
            >
              <div class="d-flex align-center">
                <checkbox
                  @change="
                    (val) => {
                      rememberCheckbox = val;
                    }
                  "
                  :label="$t('form.label.rememberMe')"
                  labellg
                />
              </div>
              <div class="d-flex align-center">
                <div>
                  <a
                    href="/forgot-password"
                    @click.prevent="$router.push({ path: '/forgot-password' })"
                    >{{ $t("button.forgotPassword") }}</a
                  >
                </div>
              </div>
            </div>

            <v-btn
              large
              @click.prevent="login()"
              color="primary"
              block
              elevation="0"
              class="mt-6"
              :loading="loading"
            >
              <span class="font-weight-600">{{ $t("button.logIn") }}</span>

              <template v-slot:loader>
                <loader :color="'white'" :size="24" />
              </template>
            </v-btn>
            <v-btn
              v-for="provider in authProviders"
              :key="provider"
              @click.prevent="socialAuth(provider)"
              :loading="authLoading"
            >
              {{ $t("Sign in with") }} {{ provider }}
            </v-btn>
          </v-card-text>
        </v-card>
        <LanguageSwitcher isLogin class="mt-10" />
      </v-col>
    </v-row>
    <v-img
      :src="require('@/assets/illustrations/radio-lines.svg')"
      class="radio-lines"
    />
  </v-container>
</template>

<script>
import Api from "@/apis/Api";
import Auth from "@/apis/Auth";

import FormRulesMixin from "../mixins/FormRulesMixin";
import Loader from "../components/Loader.vue";

import Checkbox from "../components/buttons/Checkbox.vue";
import CollapseTransitions from "../components/transitions/CollapseTransitions.vue";
import CopyTextButton from "../components/buttons/CopyTextButton.vue";
import LanguageSwitcher from "../components/LanguageSwitcher.vue";

export default {
  components: {
    Checkbox,
    CollapseTransitions,
    Loader,
    CopyTextButton,
    LanguageSwitcher,
  },
  mixins: [FormRulesMixin],
  data: function () {
    let email = "";
    let password = "";

    if (this.$store.state.demoMode) {
      email = "demo@panelalpha.com";
      password = "Password123";
    }

    return {
      email: email,
      password: password,
      errors: [],
      rememberCheckbox: false,
      loading: false,
      authLoading: false,
      twoFactorAuthEnabled: false,
      twoFactorAuthCode: "",
      twoFactorAuthData: {},
      twoFAErrors: [],

      backupCodeRegenerated: false,
      recoveryCode: "",
      confirmedRecoverCode: false,
    };
  },
  beforeRouteEnter: async (__, _, next) => {
    await Auth.remoteAuthCheck().then((response) => {
      if (response === true) {
        next((vm) => {
          vm.$root.$emit("loggedIn", true);
          next(vm.$route.query.redirect || "/");
        });
      } else {
        next();
      }
    });
  },
  computed: {
    customLogo: function () {
      if (!this.$store.state.ThemeModule.logo) {
        return false;
      }

      return this.$store.state.ThemeModule.logo;
    },
    isLogoBase64: function () {
      return (
        this.customLogo && this.customLogo.trim().startsWith("data:image/")
      );
    },
    isDemo() {
      return this.$store.state.home.user.email == "demo@panelalpha.com";
    },
    authProviders() {
      return this.$store.state.home.app.integrations.auth;
    },
  },
  mounted: function () {
    document.addEventListener("keyup", (e) => this.handleEnter(e));
    Auth.loadRecaptchaScript();
  },
  destroyed: function () {
    document.removeEventListener("keyup", (e) => this.handleEnter(e));
  },
  methods: {
    twoFactorAuthBack: function () {
      this.twoFactorAuthEnabled = false;
    },
    handleEnter(e) {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        this.login();
      }
    },
    async login() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      Auth.login(this.email, this.password, this.rememberCheckbox)
        .then((response) => {
          if (response.data.two_factor === true) {
            this.errors = [];
            this.twoFactorAuthEnabled = true;
            return;
          }
          this.$root.$emit("loggedIn", true);
          this.acceptInvite().then(() => {
            Auth.hideRecaptchaBadge();
            this.$router.push({ path: this.$route.query.redirect || "/" });
          });
        })
        .catch((error) => {
          if (error.response.status === 429) {
            this.errors = [error.response.data.message.replace(".", "")];
            return;
          }
          let newErrors = [];
          let errors = error.response.data.errors;
          if (!Array.isArray(errors)) {
            errors = Object.values(errors);
          }
          errors.map((item) => {
            item.map((newError) => {
              newErrors.push(newError.replace(".", ""));
            });
          });
          this.errors = newErrors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loginAfterTwoFactorAuth() {
      this.loading = true;
      Auth.loginWithTwoFactorAuth(this.twoFactorAuthData)
        .then(() => {
          //zrobić ifa czy twoFactorAuthCode > 6
          //jeśli jest to należy wyświetlić stronę
          this.$root.$emit("loggedIn", true);

          this.acceptInvite().then(() => {
            if (this.twoFactorAuthCode.length > 6) {
              Api.post("/user/two-factor-recovery-codes").then(() => {
                Api.get("/user/two-factor-recovery-codes").then((response) => {
                  this.recoveryCode = response.data;
                  this.twoFactorAuthEnabled = false;
                  this.backupCodeRegenerated = true;
                });
              });
            } else {
              Auth.hideRecaptchaBadge();
              this.$router.push({ path: this.$route.query.redirect || "/" });
            }
          });
        })
        .catch((error) => {
          let newErrors = [];
          if (error.response.status === 429) {
            newErrors.push(error.response.data.message.replace(".", ""));
          } else {
            if (this.twoFactorAuthCode.length <= 6) {
              error.response.data.errors.code.map((item) => {
                newErrors.push(item.replace(".", ""));
              });
            } else {
              error.response.data.errors.recovery_code.map((item) => {
                newErrors.push(item.replace(".", ""));
              });
            }
          }
          this.twoFAErrors = newErrors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    acceptInvite() {
      if (!this.$route.query.invite_token) {
        return Promise.resolve(true);
      }
      return Api.post("/accept-invitation", {
        token: this.$route.query.invite_token,
      })
        .then(() => {
          return Promise.resolve(true);
        })
        .catch(() => {
          return Promise.resolve(true);
        });
    },
    confirmCheckbox() {
      this.confirmedRecoverCode = !this.confirmedRecoverCode;
    },
    continueAfterBackupCode() {
      this.$router.push({ path: this.$route.query.redirect || "/" });
    },

    socialAuth(provider) {
      this.authLoading = true;
      Api.get(`/auth/${provider}/redirect-url`)
        .then((response) => {
          window.location = response.data.data.url;
        })
        .catch((error) => {
          this.errors = [Api.getErrorMessage(error)];
        })
        .finally(() => {
          this.authLoading = false;
        });
    },
  },
  watch: {
    twoFactorAuthCode() {
      if (this.twoFactorAuthCode.length <= 6) {
        this.twoFactorAuthData = { code: this.twoFactorAuthCode };
      } else {
        this.twoFactorAuthData = { recovery_code: this.twoFactorAuthCode };
      }
    },
  },
};
</script>

<style src="./Login.scss" lang="scss" scoped />
